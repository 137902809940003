import styles from './Checkbox.module.scss';
import PropTypes from 'prop-types';
import { CheckedIcon } from 'components/primitives/icons';

const Checkbox = ({ className = '', title, ...attributes }) => (
  // tabIndex is used to prevent focus been set on any parent focusable container which holds label element when clicking on it.
  <label className={styles.lbl} tabIndex="-1">
    <span className={`${styles.chb} ${className}`} title={title} role={title ? null : 'presentation'}>
      <input type="checkbox" {...attributes} />
      <ins aria-hidden>{/*ins right after input[type="checkbox"] to fix iOS bug*/}
        <CheckedIcon />
      </ins>
    </span>
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Checkbox;
